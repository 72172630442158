export const state = () => ({
  polling: true,
  poll: false,
  components: {
    // poll: false
  },
  searchCategory: 0,
  componentFinderStep: 0,
  runningFinder: [],
  finishedFinder: []
})

export const mutations = {
  SET_COMPONENT_FINDER (state, payload) {
    const componentData = {
      path: payload.path,
      componentIdentifier: payload.componentIdentifier
    }
    if (!state.components[payload.id]) {
      state.components[payload.id] = []
    }
    state.poll = !state.poll
    state.components[payload.id] = [...state.components[payload.id], componentData]
  },
  SET_RUNNING_FINDER (state, id) {
    state.runningFinder = [...new Set([...state.runningFinder, id])]
  },
  REMOVE_RUNNING_FINDER (state, id) {
    state.runningFinder = state.runningFinder.filter(val => val !== id)
    state.finishedFinder = [...new Set([...state.finishedFinder, id])]
  },
  SET_FINISHED_FINDER (state, id) {
    // state.finishedFinder = [...new Set([...state.finishedFinder, id])]
  },
  SET_CATEGORY (state, cat) {
    state.searchCategory = cat
  },
  SET_COMPONENT_FINDER_STEP (state) {
    state.componentFinderStep++
  },
  RESET_COMPONENT_FINDER_STEP (state) {
    state.componentFinderStep = 0
  }
}

export const actions = {
  setComponentFinder ({ commit }, payload) {
    if (payload.componentIdentifier.startsWith('cat_')) {
      commit('SET_COMPONENT_FINDER_STEP')
    }
    commit('SET_COMPONENT_FINDER', payload)
  },
  resetComponentFinderStep ({ commit }) {
    commit('RESET_COMPONENT_FINDER_STEP')
  },
  setRunningFinder ({ commit }, id) {
    commit('SET_RUNNING_FINDER', id)
  },
  removeRunningFinder ({ commit }, id) {
    commit('REMOVE_RUNNING_FINDER', id)
  },
  setFinishedFinder ({ commit }, id) {
    commit('SET_FINISHED_FINDER', id)
  }
}

export const getters = {
  getPoll: state => state.poll,
  getComponentFinder: (state) => {
    return (id) => {
      const components = { ...state.components }
      return components[id]
    }
  },
  getRunningFinder: state => state.runningFinder,
  getFinishedFinder: state => state.finishedFinder,
  getComponentFinderStep: state => state.componentFinderStep
}
