/**
 * Store for monitoring status information.
 */

const MAX_AGE_IN_MINUTES = 1

export const state = () => ({
  status: { servers: { down: null, openAlerts: null, used: null }, websites: { down: null, openAlerts: null, used: null } },
  lastUpdate: 0,
  polling: true
})

export const mutations = {
  SET_STATUS (state, status) {
    state.status = status
  },
  SET_LAST_UPDATE (state, timestamp) {
    state.lastUpdate = timestamp
  },
  SET_POLLING (state, polling) {
    state.polling = polling
  }
}

export const actions = {
  async updateWarnings ({ commit, rootGetters, state }) {
    if (new Date().getTime() < state.lastUpdate + (MAX_AGE_IN_MINUTES * 60 * 1000)) {
      commit('SET_STATUS', state.status)
      return
    }
    commit('SET_POLLING', true)

    commit('SET_LAST_UPDATE', new Date().getTime())

    const companyId = rootGetters['access/getCompanyId']
    const repo = await this.$apiClient.getRepository('incident')
    const { status } = await repo.findByCompany(companyId)

    commit('SET_STATUS', status)
    commit('SET_POLLING', false)
  }
}

export const getters = {
  getStatus: state => state.status,
  getPollingState: state => state.polling
}
