/**
 * Modular Store for testrunner
 */

export const state = () => ({
  runner: {},
  isFirstRun: false,
  polling: true
})

export const mutations = {
  POLLING_START (state) {
    state.polling = true
  },
  POLLING_FINISHED (state) {
    state.polling = false
  },
  SET_RUNNER (state, payload) {
    state.runner = payload
  },
  SET_RUNNER_TIMESTAMP (state, time) {
    state.runner.finishTimestamp = time
  },
  SET_FIRSTRUN (state, status) {
    state.isFirstRun = status
  }
}

export const actions = {
  async initiateRunner ({ commit, state, $sentry }, sid) {
    commit('POLLING_START')
    if (!sid) return
    try {
      const systemRepo = await this.$apiClient.getRepository('system')
      const { lastRunInSeconds, nextRunInSeconds, isFirstRun } = await systemRepo.getNextLastFullRun(sid)
      const cycleTime = lastRunInSeconds + nextRunInSeconds
      const progress = Math.floor(lastRunInSeconds / (cycleTime) * 100)
      const progressPerSecond = 100 / (cycleTime)

      commit('POLLING_FINISHED')
      commit('SET_RUNNER', {
        progress,
        progressPerSecond,
        nextRunInMs: nextRunInSeconds * 1000
      })
      commit('SET_FIRSTRUN', isFirstRun)
    } catch (error) {
      commit('POLLING_FINISHED')
      $sentry.captureException(error)
    }
  },

  updateRunnerTimestamp ({ commit }, time) {
    commit('SET_RUNNER_TIMESTAMP', time)
  },
  setFirstRun ({ commit }, status) {
    commit('SET_FIRSTRUN', status)
  }
}

export const getters = {
  getRunner: state => !state.polling ? state.runner : null,
  getPollingState: state => state.polling,
  getFirstRun: state => state.isFirstRun
}
