import { LeankoalaClient } from '@webpros/360-api-client'

export default ({ $axios, params, store, $sentry = false }, inject) => {
  const apiClient = new LeankoalaClient(process.env.apiEnv, process.env.provider)
  if ($sentry) {
    apiClient.on('error', (exception) => {
      $sentry.captureException(exception)
    })
  }

  apiClient.on('error', (exception) => {
    console.error('Error running API request: ', exception)
  })

  apiClient.on('failure', (failure) => {
    console.warn('Failure running API request: ', failure.message)
  })

  apiClient.on('refresh.invalid', () => {
    store.action.access.logoutUser()
  })

  inject('apiClient', apiClient)
}
