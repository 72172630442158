/**
 * Modular Store for components
 */

export const state = () => ({
  components: [],
  suggestions: {},
  suggestionTypes: [],
  polling: true,
  sid: null
})

export const mutations = {
  SET_SID (state, sid) {
    state.sid = sid
  },
  SET_COMPONENTS (state, components) {
    state.components = components
  },
  SET_SUGGESTIONS (state, suggestions) {
    state.suggestions = suggestions
  },
  SET_SUGGESTION_TYPES (state, types) {
    state.suggestionTypes = types
  },
  UPDATE_COMPONENT (state, payload) {
    const component = state.components.find(val => val.id === payload.id)
    component.url = payload.data.url
  },
  CREATE_COMPONENT (state, payload) {
    state.components = [...state.components, payload]
  },
  DELETE_COMPONENT (state, id) {
    state.components = state.components.filter(val => val.id !== id)
  },
  CLEAR_COMPONENTS (state) {
    state.components = []
  },
  CLEAR_SUGGESTIONS (state) {
    state.suggestions = {}
  },
  POLLING (state, status) {
    state.polling = status
  }
}

export const actions = {
  async collect ({ commit, dispatch, state, $sentry }, sid) {
    if (!sid) return
    commit('POLLING', true)
    try {
      const repo = await this.$apiClient.getRepository('system')
      const componentsRaw = await repo.getComponents(sid)
      commit('SET_COMPONENTS', componentsRaw.components)

      if (state.sid !== sid) {
        const suggestionsRaw = await repo.getComponentSuggestions(sid)
        const suggestionTypes = Object
          .entries(suggestionsRaw.suggestions)
          .map(val => val[1].map(val => val.identifier))
          .flat()
        commit('SET_SUGGESTION_TYPES', suggestionTypes)
        commit('SET_SUGGESTIONS', suggestionsRaw.suggestions)
      }
    } catch (error) {
      console.error(error)
      $sentry.captureException(error)
    } finally {
      commit('POLLING', false)
      dispatch('screenshots/collect', sid, { root: true })
      commit('SET_SID', sid)
    }
  },
  updateComponent ({ commit }, payload) {
    commit('UPDATE_COMPONENT', payload)
  },
  deleteComponent ({ commit }, id) {
    commit('DELETE_COMPONENT', id)
  },
  createComponent ({ commit }, payload) {
    commit('CREATE_COMPONENT', payload)
  },
  clearState ({ commit }) {
    commit('POLLING', false)
    commit('CLEAR_COMPONENTS')
    commit('CLEAR_SUGGESTIONS')
    commit('SET_SID', null)
  }
}

export const getters = {
  getComponents: state => state.components,
  getComponent: state => id => state.components.find(val => val.id === id),
  getSuggestions: state => state.suggestions,
  getSuggestionTypes: state => state.suggestionTypes,
  getPolling: state => state.polling
}
