import { debugEcho } from '~/utils/debugTools'

export default ({ context, store, redirect, $apiClient, route }) => {
  const whitelabelConfig = store.getters['whitelabel/getConfig']
  const gtmId = whitelabelConfig.gtmId
  if (gtmId) {
    debugEcho('GTM Load')
      if (process.client) {
        (function (w, d, s, l, i) {
          w[l] = w[l] || []
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
          const f = d.getElementsByTagName(s)[0]
          const j = d.createElement(s)
          const dl = l !== 'dataLayer' ? '&l=' + l : ''
          j.async = true
          j.src = 'https://srv.360monitoring.com/nntctptb.js?id=' + i + dl
          f.parentNode.insertBefore(j, f)
        })(window, document, 'script', 'dataLayer', String(gtmId))
      }
  } else {
    debugEcho('Do not load GTM')
  }
}
