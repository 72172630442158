/**
 * Modular Store for messaging
 */

export const state = () => ({
  subscriptionReduction: {}
})

export const mutations = {
  SET_SUBSCRIPTION_REDUCTION (state, payload) {
    state.subscriptionReduction = payload
  }
}

export const actions = {
  setSubscriptionReduction ({ commit }, payload) {
    commit('SET_SUBSCRIPTION_REDUCTION', payload)
  }
}

export const getters = {
  getSubscriptionReduction: state => state.subscriptionReduction
}
