/**
 * Modular Store for header
 */

export const state = () => ({
  projects: [],
  actualProject: null,
  actualSystem: null,
  userId: null,
  projectsLoaded: false,
  error: null,
  projectInitialSelected: false,
  sids: null,
  pids: null,
  polling: true
})

export const mutations = {
  SET_POLLING (state, status) {
    state.polling = status
  },
  UPDATE_PROJECTS (state, payload) {
    state.projects = payload
  },

  CLEAR_PROJECT_STATE (state) {
    state.actualProject = null
    state.actualSystem = null
    state.projects = []
    state.projectsLoaded = false
    state.userId = null
    state.pids = null
    state.sids = null
  },

  SET_PROJECT (state, payload) {
    state.actualProject = parseInt(payload)
  },
  SET_SYSTEM (state, payload) {
    state.actualSystem = parseInt(payload)
  },
  SET_USERID (state, id) {
    state.userId = id
  },
  SET_PROJECTS_LOADED (state, status) {
    state.projectsLoaded = status
  },
  SET_ERROR (state, error) {
    state.error = error
  },
  SET_PROJECT_INITIAL_SELECTED (state) {
    state.projectInitialSelected = true
  },
  SET_PIDS_AND_SIDS (state, payload) {
    state.pids = payload.pids
    state.sids = payload.sids
  },
  TEMP_UPDATE_SINGLE_PROJECT (state, payload) {
    const { pid, sid, name, domain } = payload
    const project = state.projects.find(val => val.id === pid)
    const system = project.systems.find(val => val.id === sid)
    project.name = name
    system.name = name
    system.domain = domain
  }
}

export const actions = {
  async updateProject ({ commit, state, $sentry }) {
    commit('SET_POLLING', true)
    if (state.userId !== null) {
      try {
        const projectRepo = await this.$apiClient.getRepository('project')
        const projects = await projectRepo.search({
          user: state.userId,
          with_next_full_run: true,
          with_onboarding_status: true,
          with_features: true,
          filter_empty_projects: true
        })

        const cleanEntries = projects.projects.filter((val) => {
          if (val.systems && val.systems.length) {
            return val
          }
          return null
        })

        const pids = cleanEntries.map(val => val.id)
        const sids = cleanEntries.map((val) => {
          return val.systems.map(val => val.id)
        }).flat()

        commit('SET_PIDS_AND_SIDS', { pids, sids })
        commit('SET_PROJECTS_LOADED', true)
        commit('UPDATE_PROJECTS', cleanEntries)
      } catch (error) {
        $sentry.captureException(error)
        commit('SET_ERROR', error)
      } finally {
        commit('SET_POLLING', false)
      }
    }
  },

  clearProjectState ({ commit }) {
    commit('CLEAR_PROJECT_STATE')
  },

  setInitialProject ({ commit, dispatch }, payload) {
    commit('SET_PROJECT', payload.pid)
    commit('SET_SYSTEM', payload.sid)
    dispatch('incidents/projectIncidentGuard', payload.pid, { root: true })
    dispatch('scores/systemScoreGuard', payload.sid, { root: true })
    dispatch('onboarding/updateOnboarding', payload.pid, { root: true })
    dispatch('pageComponents/collect', payload.sid, { root: true })
    dispatch('navigation/updateNavigation', { pid: payload.pid }, { root: true })
  },

  setSystem ({ commit }, payload) {
    commit('SET_SYSTEM', payload)
  },

  setProjectSystem ({ commit, dispatch }, payload) {
    commit('SET_PROJECT', payload.pid)
    commit('SET_SYSTEM', payload.sid)
    dispatch('scores/systemScoreGuard', payload.sid, { root: true })
    dispatch('incidents/projectIncidentGuard', payload.pid, { root: true })
    dispatch('onboarding/updateOnboarding', payload.pid, { root: true })
    dispatch('pageComponents/collect', payload.sid, { root: true })
    dispatch('navigation/updateNavigation', { pid: payload.pid }, { root: true })
  },

  setUserId ({ commit }, id) {
    commit('SET_USERID', id)
  },

  tempUpdateSingleProject ({ commit }, payload) {
    commit('TEMP_UPDATE_SINGLE_PROJECT', payload)
  }
}

export const getters = {
  getProjects (state) {
    return state.projects
  },

  getProjectStatus (state) {
    return !!state.projects.length
  },

  getActualProject (state) {
    return state.actualProject
  },

  getActualSystem (state) {
    return state.actualSystem
  },

  getActualProjectDetails (state) {
    if (!state.projects) return false
    return state.projects.find(val => val.id === state.actualProject)
  },

  getActualSystemDomain (state) {
    if (!state.projects) return
    return state.projects.find(val => val.id === state.actualProject).systems.find(val => val.id === state.actualSystem).domain
  },
  getActualSystemName (state) {
    if (!state.projects) return
    return state.projects.find(val => val.id === state.actualProject)?.name
  },

  getProjectLoadedState (state) {
    return state.projectsLoaded
  },

  getCurrentProjectFeatures (state) {
    if (!state.projects) return false
    const currentProject = state.projects.find(val => val.id === state.actualProject)
    return currentProject.features
  },

  getError (state) {
    return state.error
  },
  getPidsAndSids (state) {
    return { pids: state.pids, sids: state.sids }
  },
  getScreenshots (state) {
    const screens = {}
    state.projects.forEach((val) => {
      return val.systems.forEach((sys) => {
        screens[sys.id] = {
          name: sys.name,
          raw: sys.screenshot?.raw,
          thumb: sys.screenshot?.thumb
        }
      })
    })
    return screens
  },
  getPolling (state) {
    return state.polling
  },

  getProjectRole (state) {
    return state.projects.find(val => val.id === state.actualProject)?.role?.id
  }
}
