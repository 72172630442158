/**
 * Modular Store for screenshots
 */

export const state = () => ({
  screenshots: {},
  polling: true,
  sid: null
})

export const mutations = {
  SET_SCREENSHOTS (state, payload) {
    state.screenshots = payload
  },
  CLEAR_SCREENSHOTS (state) {
    state.screenshots = {}
  },
  DELETE_SCREENSHOT (state, id) {
    delete state.screenshots[id]
  },
  SCREENSHOT_PENDING_START (state, id) {
    state.screenshots[id] = { ...state.screenshots[id], pending: true }
  },
  SCREENSHOT_PENDING_FINISH (state, id) {
    state.screenshots[id] = { ...state.screenshots[id], pending: false }
  },
  SET_SID (state, sid) {
    state.sid = sid
  },
  POLLING (state, status) {
    state.polling = status
  }
}

export const actions = {
  async collect ({ commit, dispatch, state, $sentry }, sid) {
    if (!sid) return
    commit('POLLING', true)
    try {
      if (state.sid !== sid) {
        commit('CLEAR_SCREENSHOTS')
      }
      const repo = await this.$apiClient.getRepository('screenshot')
      const screenshotRaw = await repo.getSystemScreenshots(sid)

      const screenshots = Array.isArray(screenshotRaw.screenshots)
        ? {}
        : screenshotRaw.screenshots

      commit('SET_SCREENSHOTS', screenshots)
    } catch (error) {
      console.error(error)
      $sentry.captureException(error)
    } finally {
      commit('POLLING', false)
      commit('SET_SID', sid)
    }
  },
  activateScreenshotPending ({ commit }, id) {
    commit('SCREENSHOT_PENDING_START', id)
  },
  finishScreenshotPending ({ commit }, id) {
    commit('SCREENSHOT_PENDING_FINISH', id)
  },
  deleteScreenshot ({ commit }, id) {
    commit('DELETE_SCREENSHOT', id)
  }
}

export const getters = {
  getPolling: state => state.polling,
  getScreenshots: state => state.screenshots,
  getScreenshot: state => id => state.screenshots[id]
}
