/**
 * Modular Store for ui
 */

export const state = () => ({
  metaSidebarOpen: false,
  secondScreen: false,
  secondScreenUrl: '',
  secondScreenHeader: '',
  scrollPosition: 0,
  freshLogin: true,
  informationCards: null,
  cardTransition: 'default',
  isFirstRun: {},
  initReporting: null,
  visibleUi: true
})

export const mutations = {
  SET_RERENDER_UI (state, flag) {
    state.visibleUi = flag
  },
  SET_INIT_REPORTING (state, payload) {
    state.initReporting = payload
  },
  SET_INFORMATION_CARDS (state, cards) {
    state.informationCards = cards
  },
  SET_CARD_TRANSITION (state, transition) {
    state.cardTransition = transition
  },
  RESET_INFORMATION_CARDS (state) {
    state.informationCards = null
  },
  UNSET_FRESHLOGIN (state) {
    state.freshLogin = false
  },
  SET_SCROLLPOSITION (state, position) {
    state.scrollPosition = position
  },
  TOGGLE_SECOND_SCREEN (state) {
    state.secondScreen = !state.secondScreen
  },
  CLOSE_SECOND_SCREEN (state) {
    state.secondScreen = false
  },
  OPEN_SECOND_SCREEN (state) {
    state.secondScreen = true
  },
  SET_SECONDSCREEN_URL (state, url) {
    state.secondScreenUrl = url
  },
  CLEAR_SECONDSCREEN_URL (state) {
    state.secondScreenUrl = ''
  },
  SET_SECONDSCREEN_HEADER (state, title) {
    state.secondScreenHeader = title
  },
  CLEAR_SECONDSCREEN_HEADER (state) {
    state.secondScreenHeader = ''
  },
  OPEN_META_SIDEBAR (state) {
    state.metaSidebarOpen = true
  },
  CLOSE_META_SIDEBAR (state) {
    state.metaSidebarOpen = false
  },
  SET_FIRSTRUN (state, payload) {
    state.isFirstRun[payload.project] = status[payload.status]
  }
}

export const actions = {
  triggerRerenderUi ({ commit }) {
    commit('SET_RERENDER_UI', false)
    console.log('set rerender ui to false')
    setTimeout(() => {
      console.log('set rerender ui to true')
      commit('SET_RERENDER_UI', true)
    }, 100)
  },
  setInitReporting ({ commit }, payload) {
    commit('SET_INIT_REPORTING', payload)
  },
  setInformationCards ({ commit }, { transition = 'default', cards }) {
    commit('SET_INFORMATION_CARDS', cards)
    commit('SET_CARD_TRANSITION', transition)
  },
  resetInformationCards ({ commit }, cards) {
    commit('RESET_INFORMATION_CARDS')
  },
  unsetFreshlogin ({ commit }) {
    commit('UNSET_FRESHLOGIN')
  },
  setScrollPosition ({ commit }, position) {
    commit('SET_SCROLLPOSITION', position)
  },
  closeScreens ({ commit, state, dispatch }) {
    if (state.secondScreen) {
      commit('CLOSE_SECOND_SCREEN')
    }
    dispatch('preventBodyAction')
  },
  toggleSecondScreen ({ commit, dispatch, state }) {
    if (!state.secondScreenUrl.length) return false
    commit('TOGGLE_SECOND_SCREEN')
    dispatch('preventBodyAction')
  },
  openSecondScreen ({ commit, dispatch, state }) {
    if (!state.secondScreenUrl.length) return false
    commit('OPEN_SECOND_SCREEN')
    dispatch('preventBodyAction')
  },
  closeSecondScreen ({ commit, dispatch, state }) {
    if (!state.secondScreenUrl.length) return false
    commit('CLOSE_SECOND_SCREEN')
    dispatch('preventBodyAction')
  },
  setSecondScreenUrl ({ commit }, payload) {
    commit('SET_SECONDSCREEN_URL', payload.url)
    commit('SET_SECONDSCREEN_HEADER', payload.header || '')
  },
  clearSecondscreenUrl ({ commit }) {
    commit('CLEAR_SECONDSCREEN_URL')
    commit('CLEAR_SECONDSCREEN_HEADER')
  },
  preventBodyAction ({ commit, state }, payload) {
    if (process.client) {
      const body = document.querySelector('body')
      const pageBody = document.querySelector('.pageLayout__body')

      if (state.secondScreen || state.metaSidebarOpen) {
        body.style.overflow = 'hidden'
        pageBody.classList.add('pointer-events-none', 'select-none')
      } else {
        body.style = ''
        pageBody?.classList.remove('pointer-events-none', 'select-none')
      }
    }
  },
  openMetaSidebar ({ commit, dispatch }) {
    commit('OPEN_META_SIDEBAR')
    dispatch('preventBodyAction')
  },
  closeMetaSidebar ({ commit, dispatch }) {
    commit('CLOSE_META_SIDEBAR')
    dispatch('preventBodyAction')
  },
  setFirstrun ({ commit }, payload) {
    commit('SET_FIRSTRUN', payload)
  }
}

export const getters = {
  getSecondScreenState (state) {
    return state.secondScreen
  },
  getSecondScreenUrl (state) {
    return state.secondScreenUrl
  },
  getSecondScreenHeader (state) {
    return state.secondScreenHeader
  },
  getScrollPosition: state => state.scrollPosition,
  getFreshlogin: state => state.freshLogin,
  getMetaSidebarState: state => state.metaSidebarOpen,
  getInformationCards: state => state.informationCards,
  getCardTransition: state => state.cardTransition,
  getFirstrun: state => project => state.isFirstRun[project],
  getInitReportings: state => state.initReporting,
  getUiState: state => state.visibleUi
}
