export const state = () => ({
  scores: {},
  sid: null,
  polling: true
})

export const actions = {
  expireSystemScore ({ commit, state }, sid) {
    const systemScore = state.scores[sid]
    if (systemScore && systemScore.expired) {
      commit('EXPIRE_SYSTEM_SCORE', sid)
    }
  },
  systemScoreGuard ({ commit, state, dispatch }, sid) {
    commit('UPDATE_SID', sid)
    const systemScore = state.scores[sid]
    if (!systemScore) {
      const scoreCount = Object.keys(state.scores).length
      if (scoreCount >= 4) {
        dispatch('garbageCollection')
        dispatch('collectSystemScores', { sid })
      } else {
        dispatch('collectSystemScores', { sid })
      }
    } else if (systemScore && !systemScore.expired) {
      commit('UPDATE_SYSTEM_SCORE_TIMESTAMP', sid)
    } else if (systemScore && systemScore.expired) {
      dispatch('collectSystemScores', { sid })
    }
  },
  garbageCollection ({ commit, state }) {
    const timestamp = new Date().getTime()
    const oldestScore = Object.values(state.scores).reduce((a, b) => timestamp - a.timestamp > timestamp - b.timestamp ? a : b).id
    commit('REMOVE_SYSTEM_SCORE', oldestScore)
  },
  async collectSystemScores ({ commit, $sentry, state }, { sid, update = false }) {
    if (update && !state.scores[sid]) return
    commit('POLLING_START')
    try {
      const scoreRepo = await this.$apiClient.getRepository('score')
      const { scores } = await scoreRepo.getScores(sid, {
        scores: [
          'basic-performance',
          'basic-uptime',
          'basic-seo',
          'basic-content',
          'basic-tech',
          'basic-security'
        ]
      })

      const payload = {
        sid,
        score: {
          timestamp: new Date().getTime(),
          expired: false,
          id: sid,
          scores: {
            uptime: scores['basic-uptime'],
            performance: scores['basic-performance'],
            seo: scores['basic-seo'],
            content: scores['basic-content'],
            technical: scores['basic-tech'],
            security: scores['basic-security']
          }
        }
      }

      commit('CACHE_SYSTEM_SCORE', payload)
      commit('POLLING_FINISHED')
    } catch (error) {
      console.error(error)
      $sentry.captureException(error)
      commit('POLLING_FINISHED')
    }
  },
  clearScores ({ commit }) {
    commit('CLEAR_SCORES')
  }
}

export const mutations = {
  CLEAR_SCORES (state) {
    state.scores = {}
  },
  CACHE_SYSTEM_SCORE (state, payload) {
    state.scores[payload.sid] = payload.score
  },
  EXPIRE_SYSTEM_SCORE (state, sid) {
    state.scores[sid].expired = true
  },
  UPDATE_SYSTEM_SCORE_TIMESTAMP (state, sid) {
    state.scores[sid].timestamp = new Date().getTime()
  },
  REMOVE_SYSTEM_SCORE (state, id) {
    delete state.scores[id]
  },
  UPDATE_SID (state, id) {
    state.sid = id
  },
  POLLING_START (state) {
    state.polling = true
  },
  POLLING_FINISHED (state) {
    state.polling = false
  }
}

export const getters = {
  getSystemScores (state) {
    return state.polling ? {} : state.scores[state.sid].scores
  },

  getScores (state) {
    return state.polling ? {} : state.scores
  },
  getPollingState (state) {
    return state.polling
  }
}
