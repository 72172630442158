import { incidentIdList } from '@/domain/incidents'

export const state = () => ({
  incidents: {},
  pid: null,
  polling: true
})

export const actions = {
  expireProjectIncident ({ commit, state }, pid) {
    const systemIncident = state.incidents[pid]
    if (systemIncident && systemIncident.expired) {
      commit('EXPIRE_PROJECT_INCIDENT', pid)
    }
  },
  projectIncidentGuard ({ commit, state, dispatch }, pid) {
    commit('UPDATE_PID', pid)
    const projectIncident = state.incidents[pid]
    if (!projectIncident) {
      const incidentCount = Object.keys(state.incidents).length
      if (incidentCount >= 4) {
        dispatch('garbageCollection')
        dispatch('collectProjectIncidents', { pid })
      } else {
        dispatch('collectProjectIncidents', { pid })
      }
    } else if (projectIncident && !projectIncident.expired) {
      commit('UPDATE_PROJECT_INCIDENT_TIMESTAMP', pid)
    } else if (projectIncident && projectIncident.expired) {
      dispatch('collectProjectIncidents', { pid })
    }
  },
  garbageCollection ({ commit, state }) {
    const timestamp = new Date().getTime()
    const oldestIncident = Object.values(state.incidents).reduce((a, b) => timestamp - a.timestamp > timestamp - b.timestamp ? a : b).id
    commit('REMOVE_PROJECT_INCIDENT', oldestIncident)
  },
  async collectProjectIncidents ({ commit, state, $sentry }, { pid, update = false }) {
    if (update && !state.incidents[pid]) return
    commit('POLLING_START')
    try {
      const incidentRepo = await this.$apiClient.getRepository('incident')
      const [openIncidentRaw, closedIncidentsRaw] = await Promise.all([
        incidentRepo.search(pid),
        incidentRepo.since(pid, {
          days: 3
        })
      ])

      const openIncidents = openIncidentRaw.incidents
        .filter(val => incidentIdList.includes(val.tool.identifier))
      const closedIncidents = closedIncidentsRaw.incidents
        .filter(val => incidentIdList.includes(val.tool.identifier))
        .filter(val => val.end_date)

      const ids = {
        open: openIncidents.length
          ? openIncidents.map(val => val.id)
          : [],
        closed: closedIncidents.length
          ? closedIncidents.map(val => val.id)
          : []
      }

      const payload = {
        pid,
        timestamp: new Date().getTime(),
        incidents: {
          timestamp: new Date().getTime(),
          expired: false,
          id: pid,
          count: openIncidents.length,
          openIncidents,
          closedIncidents,
          ids
        }
      }

      commit('CACHE_PROJECT_INCIDENT', payload)
      commit('POLLING_FINISHED')
    } catch (error) {
      console.error(error)
      $sentry.captureException(error)
      commit('POLLING_FINISHED')
    }
  },
  clearIncidents ({ commit }) {
    commit('CLEAR_INCIDENTS')
  }
}

export const mutations = {
  CLEAR_INCIDENTS (state) {
    state.incidents = {}
  },
  CACHE_PROJECT_INCIDENT (state, payload) {
    state.incidents[payload.pid] = payload.incidents
  },
  EXPIRE_PROJECT_INCIDENT (state, pid) {
    state.incidents[pid].expired = true
  },
  UPDATE_PROJECT_INCIDENT_TIMESTAMP (state, pid) {
    state.incidents[pid].timestamp = new Date().getTime()
  },
  REMOVE_PROJECT_INCIDENT (state, id) {
    delete state.incidents[id]
  },
  UPDATE_PID (state, pid) {
    state.pid = pid
  },
  POLLING_START (state) {
    state.polling = true
  },
  POLLING_FINISHED (state) {
    state.polling = false
  }
}

export const getters = {
  getIncidentIds (state) {
    return state.polling ? { closed: [], open: [] } : state.incidents[state.pid].ids
  },
  getProjectIncidents (state) {
    return state.polling ? {} : state.incidents[state.pid]?.openIncidents
  },
  getIncidentsHistory (state) {
    return state.polling ? {} : state.incidents[state.pid]?.closedIncidents
  },
  getProjectIncidentsShortDetails (state) {
    if (state.polling) {
      return {
        count: 0,
        critical: false
      }
    } else {
      return {
        count: state.incidents[state.pid]?.count,
        critical: !!state.incidents[state.pid]?.openIncidents.find(val => val.severity.name === 'bad')
      }
    }
  },
  getClosedProjectIncidentsShortDetails (state) {
    if (state.polling) {
      return {
        count: 0,
        critical: false
      }
    } else {
      return {
        count: state.incidents[state.pid]?.closedIncidents.length,
        critical: false
      }
    }
  },
  getPollingState (state) {
    return state.polling
  }
}
