export const state = () => ({
  onboarding: {
    completed: true,
    details: {
      hasSystems: true,
      hasComponents: true,
      hasPolicies: true,
      isCustomDomain: true
    }
  },
  polling: true
})

export const actions = {
  async updateOnboarding ({ commit, $sentry }, pid) {
    commit('SET_POLLING', true)
    try {
      const projectRepo = await this.$apiClient.getRepository('project')
      const data = await projectRepo.getStatus(pid)
      commit('UPDATE_ONBOARDING', data)
    } catch (error) {
      console.error(error)
      $sentry.captureException(error)
    } finally {
      commit('SET_POLLING', false)
    }
  },
  activateSystem ({ commit, dispatch }) {
    commit('ACTIVATE_SYSTEM')
    dispatch('checkCompleted')
  },
  activateComponent ({ commit, dispatch }) {
    commit('ACTIVATE_COMPONENTS')
    dispatch('checkCompleted')
  },
  activatePolicies ({ commit, dispatch }) {
    commit('ACTIVATE_POLICIES')
    dispatch('checkCompleted')
  },
  checkCompleted ({ commit, state }) {
    const { hasSystems, hasComponents, hasPolicies, isCustomDomain } = state.onboarding.details
    if (hasSystems && hasComponents && hasPolicies && isCustomDomain) {
      commit('ACTIVATE_COMPLETED')
    }
  }
}

export const mutations = {
  SET_POLLING (state, status) {
    state.polling = status
  },
  UPDATE_ONBOARDING (state, payload) {
    state.onboarding = payload
  },
  ACTIVATE_COMPLETED (state) {
    state.onboarding.completed = true
  },
  ACTIVATE_SYSTEM (state) {
    state.onboarding.details.hasSystems = true
    state.onboarding.details.isCustomDomain = true
  },
  ACTIVATE_COMPONENTS (state) {
    state.onboarding.details.hasComponents = true
  },
  ACTIVATE_POLICIES (state) {
    state.onboarding.details.hasPolicies = true
  }
}

export const getters = {
  getOnboarding (state) {
    return state.onboarding
  },
  onboardingStatus (state) {
    return state.onboarding.completed
  },
  systemStatus (state) {
    return state.onboarding.details.hasSystems
  },
  policyStatus (state) {
    return state.onboarding.details.hasPolicies
  },
  componentStatus (state) {
    return state.onboarding.details.hasComponents
  },
  domainStatus (state) {
    return state.onboarding.details.isCustomDomain
  },
  getPolling: state => state.polling
}
