import { defineStore, storeToRefs } from 'pinia'
import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import axios from 'axios'
import { IChangeLogData } from '~/ts/interfaces/changeLogData.interfaces'

const CHANGELOG_BASE_API_URL = '/changelog'
export const CHANGELOG_BASE_URL = 'https://docs.360monitoring.com/changelog'
export const CHANGELOG_LIST = `${CHANGELOG_BASE_API_URL}/last`
export const CHANGELOG_ENTRY_DETAIL = `${CHANGELOG_BASE_API_URL}/read/`

export const changelogStore = defineStore('changelog', () => {
  const changeLogData = ref<IChangeLogData|null>(null)

  const collectChangelog = async () => {
    try {
      const data = await axios.get(CHANGELOG_LIST, {
        withCredentials: true
      })
      changeLogData.value = data.data
    } catch (err) {
      console.error(err)
    }
  }

  const getChangelogEntries = computed(() => {
    return changeLogData.value
  })
  const getChangelogCount = computed(() => {
    return changeLogData.value?.length || 0
  })

  const setReadChangelogEntry = async (id: string) => {
    try {
      await axios.post(CHANGELOG_ENTRY_DETAIL + id, {}, {
        withCredentials: true
      })
    } catch (err) {
      console.error(err)
    }
  }

  const deleteAllChangelogEntries = () => (changeLogData.value = null)

  return {
    collectChangelog,
    getChangelogEntries,
    getChangelogCount,
    setReadChangelogEntry,
    deleteAllChangelogEntries
  }
})
