/**
 * Modular Store for access
 */

import { RefreshTokenInvalidError } from '@webpros/360-api-client'
import { debugEcho } from '~/utils/debugTools'
import { changelogStore } from '~/pstore/changelog'

export const state = () => ({
  user: {
    details: {
      name: null,
      id: null,
      masterId: null,
      firstName: null,
      company: null,
      email: null,
      gravatar: null,
      preferred_language: null,
      nixstatsId: null,
      threeSixtyId: null
    },
    wakeUpToken: null
  },
  manualLogin: true,
  error: '',
  demoUser: false,
  trialUser: false,
  remainTrialTime: null,
  trailEndTime: null,
  memories: [],
  plan: null,
  subscription: null,
  timezone: 'UTC',
  deleteProjectNotification: {},
  polling: true,
  userProfileData: {}
})

export const actions = {
  async fetchSubscriptionAndPlanData ({ commit }) {
    try {
      const subscriptionRepo = await this.$apiClient.getRepositoryCollection().then(repo => repo.getSubscriptionRepository())
      const company = this.$apiClient.getCompany()
      debugEcho('COMPANY', company)
      const subscriptionDetails = await subscriptionRepo.getCompanySubscription(company.id)
      const { plans: { plan } } = subscriptionDetails

      commit('UPDATE_USER_SUBSCRIPTION', subscriptionDetails)
      commit('UPDATE_USER_PLAN', plan)
    } catch (error) {
      console.error('Error fetching subscription and plan data:', error)
    }
  },
  setUserProfileData ({ commit }, data) {
    commit('SET_USER_PROFILE_DATA', data)
  },
  setTimezone ({ commit }, timezone) {
    commit('SET_TIMEZONE', timezone)
  },
  resetError ({ commit }) {
    commit('SET_ERROR', '')
  },
  logoutUser ({ commit, dispatch, state }, redirectUrl = null) {
    if (process.client) {
      localStorage.removeItem('vuexStoreLeankoala')
      if (redirectUrl) {
        setTimeout(() => {
          window.location.href = redirectUrl
        }, 150)
      }
    }
    dispatch('clearUserData')
  },
  logoutClear ({ commit }) {
    if (process.client) {
      localStorage.removeItem('vuexStoreLeankoala')
    }
  },
  clearUserData ({ commit, dispatch }) {
    commit('CLEAR_USER_DATA')
    dispatch('projects/clearProjectState', {}, { root: true })
    dispatch('socket/clearSocket', {}, { root: true })
  },
  async loginUser ({ commit, dispatch, $sentry, state }, payload) {
    debugEcho('LoginUser', 'starting login')
    commit('SET_ERROR', '')
    const loginObject = (payload) => {
      const matrix = {
        username: {
          username: payload.username,
          password: payload.password,
          preferred_language: this.$i18n.locale,
          withMemories: true,
          withFeatures: true,
          autoSelectCompany: true
        },
        wakeUpToken: {
          wakeUpToken: payload.wakeUpToken,
          preferred_language: this.$i18n.locale,
          withMemories: true,
          withFeatures: true
        },
        sessionToken: {
          sessionToken: payload.sessionToken,
          preferred_language: this.$i18n.locale,
          withMemories: true,
          withFeatures: true,
          autoSelectCompany: true
        },
        refreshToken: {
          refreshToken: payload.refreshToken,
          accessToken: payload.accessToken,
          userId: payload.userId,
          user: payload.user,
          language: process.env.locale,
          withMemories: true,
          withFeatures: true,
          autoSelectCompany: true
        }
      }
      if (Object.keys(payload).includes('wakeUpToken')) {
        return matrix.wakeUpToken
      } else if (Object.keys(payload).includes('refreshToken')) {
        return matrix.refreshToken
      } else if (Object.keys(payload).includes('sessionToken')) {
        return matrix.sessionToken
      } else {
        return matrix.username
      }
    }
    commit('SET_POLLING', true)
    try {
      const client = await this.$apiClient.connect(loginObject(payload))
      debugEcho('LoginUser', 'start apiClient')
      if (client?.error) {
        window.location.href = '/'
        return
      }

      const userData = await this.$apiClient.getUser()
      userData.wakeUpToken = await this.$apiClient.getWakeUpToken()
      debugEcho('LoginUser', 'getting WakeUpToken')
      const isDemouser = process.env.demoUser === userData.username
      const company = this.$apiClient.getCompany()
      debugEcho('COMPANY Gotten', company)
      if (!company) {
        throw new Error('No company found, please select a company or use autoSelectCompany flag.')
      }

      userData.company = company
      userData.company.masterId = company.id
      userData.company.id = 'm' + company.id

      const subscriptionRepo = await this.$apiClient.getRepositoryCollection().then(repo => repo.getSubscriptionRepository())
      const subscriptionDetails = await subscriptionRepo.getCompanySubscription(userData.company.id)
      debugEcho('Subscription Details Gotten', subscriptionDetails)
      commit('UPDATE_USER_SUBSCRIPTION', subscriptionDetails)
      const isTrialUser = subscriptionDetails.status === 'trial'
      const provider = userData.application || process.env.provider

      const { plans: { plan } } = subscriptionDetails

      let memories = {}

      commit('UPDATE_USER_PLAN', plan)

      if (userData.memories) {
        memories = userData.memories
      }

      const language = userData.preferredLanguage
      if (language) {
        this.$i18n.locale = language
        this.$i18n.setLocaleCookie(language)
        this.$apiClient.setLanguage(language)
      }

      if (isTrialUser) {
        const trialEndTime = subscriptionDetails.trial_end > 0 ? subscriptionDetails.trial_end : -1
        commit('SET_TRIAL_END_TIME', trialEndTime)
        const days = parseInt(trialEndTime / (60 * 60 * 24))
        commit('SET_REMAIN_TRIAL_TIME', days)
      }

      // dispatch('projects/setUserId', userData.clusterId, { root: true })
      // dispatch('projects/updateProject', {}, { root: true })
      if (!this.app.context.route.params?.pid) {
        dispatch('navigation/updateNavigation', { provider }, { root: true })
      }
      debugEcho('LoginUser', 'setup all stores with userDate')
      dispatch('navigation/setProvider', provider, { root: true })
      dispatch('whitelabel/setupConfig', {}, { root: true })
      commit('SET_TRIALUSER', isTrialUser)
      commit('SET_DEMOUSER', isDemouser)
      userData.threeSixtyId = JSON.parse(userData.wakeUpToken).user.threeSixtyId
      userData.nixstatsId = JSON.parse(userData.wakeUpToken).cluster.user['360'].nixstats_id
      commit('SET_USER_DATA', userData)
      commit('SET_MEMORIES', memories)
      commit('SET_POLLING', false)
      // Get all changelogs
      changelogStore().collectChangelog()
      await dispatch('monitoringStatusStore/updateWarnings', {}, { root: true })
      debugEcho('LoginUser', 'finished login')
    } catch (error) {
      console.error('error', error)
      commit('SET_ERROR', error.message)
      commit('SET_POLLING', false)

      if (error instanceof RefreshTokenInvalidError) {
        dispatch('access/logoutUser', {}, { root: true })
      }
      throw (error)
    }
  },

  clearUser ({ commit }) {
    commit('CLEAR_USER_DATA')
  },
  setGravatar ({ commit }, payload) {
    commit('SET_GRAVATAR', payload)
  },
  resetTrial ({ commit }) {
    commit('SET_TRIALUSER', false)
  },
  updateDeleteProjectNotification ({ commit }, payload) {
    commit('SET_DELETE_PROJECT_NOTIFICATION', payload)
  },
  setDeleteProjectNotificationIsSeen ({ commit }) {
    commit('SET_DELETE_PROJECT_NOTIFICATION_IS_SEEN')
  },
  clearDeleteProjectNotification ({ commit }) {
    commit('CLEAR_DELETE_PROJECT_NOTIFICATION')
  }
}
export const mutations = {
  SET_USER_PROFILE_DATA (state, payload) {
    state.userProfileData = payload
  },
  SET_POLLING (state, pollState) {
    state.polling = pollState
  },
  SET_TIMEZONE (state, timezone) {
    state.timezone = timezone
  },
  UPDATE_USER_SUBSCRIPTION (state, subscription) {
    state.subscription = subscription
  },
  UPDATE_USER_PLAN (state, plan) {
    state.plan = plan
  },
  UPDATE_DELETE_PROJECT_NOTIFICATION (state, payload) {
    state.deleteProjectNotification = payload
  },
  SET_DELETE_PROJECT_NOTIFICATION_IS_SEEN (state) {
    if (!Object.keys(state.deleteProjectNotification).length) return
    state.deleteProjectNotification.notificationSeen = true
  },
  CLEAR_DELETE_PROJECT_NOTIFICATION (state) {
    state.deleteProjectNotification = {}
  },
  SET_TRIAL_END_TIME (state, time) {
    state.trailEndTime = time
  },
  SET_REMAIN_TRIAL_TIME (state, time) {
    state.remainTrialTime = time
  },
  SET_MEMORIES (state, memories) {
    state.memories = memories
  },
  SET_TRIALUSER (state, status) {
    state.trialUser = status
  },
  SET_DEMOUSER (state, status) {
    state.demoUser = status
  },
  SET_ERROR (state, err) {
    state.error = err
  },
  SET_MANUAL_LOGIN (state, payload) {
    state.manualLogin = payload
  },
  SET_USER_DATA (state, payload) {
    state.user = {
      details: {
        ...state.user.details,
        name: payload.userName,
        id: payload.id,
        masterId: payload.masterId,
        firstName: payload.firstName,
        lastName: payload.lastName,
        company: payload.company ?? null,
        preferredLanguage: payload.preferredLanguage,
        email: payload.email,
        // gravatar: payload.gravatar,
        nixstatsId: payload.nixstatsId,
        threeSixtyId: payload.threeSixtyId
      },
      wakeUpToken: payload.wakeUpToken
    }

    if (payload.preferred_language) {
      console.info('Switching language to ' + payload.preferred_language)
      this.$i18n.locale = payload.preferred_language
    }

    if (this.$Tawk) {
      this.$Tawk.updateChatUser(payload)
    }
  },

  CLEAR_USER_DATA (state) {
    state.user = {
      details: {
        name: null,
        id: null,
        firstName: null,
        lastName: null,
        company: null,
        preferredLanguage: null,
        gravatar: null,
        nixstatsId: null,
        threeSixtyId: null
      },
      wakeUpToken: null,
      plan: null,
      subscription: null
    }
    state.manualLogin = true
    state.error = ''
    state.demoUser = false
    state.trialUser = false
    state.remainTrialTime = null
  },
  SET_GRAVATAR (state, payload) {
    state.user.details.gravatar = payload
  }
}

export const getters = {
  getToken: state => state.user.accessToken,
  getUserId: state => state.user.details.id,
  getMasterUserId: state => state.user.details.masterId,
  getWakeUpToken: state => state.user.wakeUpToken,
  getManualLogin: state => state.manualLogin,
  getError: state => state.error,
  getUserDetails: state => state.user.details,
  getDemouser: state => state.demoUser,
  getTrialuser: state => state.trialUser,
  getRemainTrialTime: state => state.remainTrialTime,
  getTrialEndTime: state => state.trailEndTime,
  getUserRole: state => state.user.details.company.role.id,
  getProvider: state => state.user.details.company.provider.identifier,
  getCompanyId: state => state.user.details.company.id,
  getUserPlan: state => state.plan,
  getUserSubscription: state => state.subscription,
  getTimezone: state => state.timezone,
  getPolling: state => state.polling,
  getUserProfileData: state => state.userProfileData
}
