// import config, { partnerDomains } from '~/domain/whitelabelConfig'
import { debugEcho } from '~/utils/debugTools'
import { storeInitialPath } from '~/utils/pathStore'

// const isCurrentDomainUsingSSOLogin = () => {
//   const domain = window.location.hostname
//   const confEntry = config[domain]
//   return confEntry && confEntry.useSessionLogin
// }

// const hostname = window.location.hostname
// const isPartner = partnerDomains.includes(hostname)

export default ({ store, redirect, $apiClient, route }) => {
  const isPartner = store.getters['whitelabel/getIsPartner']
  debugEcho('Heimdal:', 'Hello Heimdal')
  if ($apiClient.isConnected()) return
  const wakeUpToken = store.getters['access/getWakeUpToken']
  storeInitialPath(route)

  if (!wakeUpToken) {
    if (isPartner) {
      debugEcho('Heimdal:', 'no wakeuptoken, isPartner')
      store.dispatch('access/clearUserData')
      return redirect('/')
    }
    debugEcho('Heimdal:', 'no wakeuptoken, not partner')
    const finalPath = process.env.NODE_ENV === 'development' ? '/' : '/sitecheck/'
    store.dispatch('access/logoutUser', finalPath)
    return
  }

  if (!$apiClient.isWakeUpTokenExpired(wakeUpToken)) {
    debugEcho('Heimdal:', 'wakeuptoken not expired')
    store.dispatch('access/loginUser', { wakeUpToken })
    return
  }
  debugEcho('Heimdal:', 'wakeuptoken expired')
  return redirect('/')
}
