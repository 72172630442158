/**
 * Modular Store for navigation
 */

import { navigation, navAliases } from '@/domain/navigation'
import { flatNavigation, generateNavigation } from '@/domain/navigationTools'

export const state = () => ({
  navigation: [],
  navAliases,
  flatnav: {},
  navPath: {
    main: null,
    sub: null,
    group: null
  },
  features: {
    provider: [],
    company: [],
    project: []
  },
  combinedFeatures: [],
  pluginIncidents: [],
  polling: true,
  provider: null
})

export const mutations = {
  OVERWRITE_MAIN_NAV (state, payload) {
    state.navPath.main = payload
  },
  SET_NAV_PATH (state, payload) {
    state.navPath = payload
  },
  CLEAR_NAV_PATH (state) {
    state.navPath = {
      main: null,
      sub: null,
      group: null
    }
  },
  UPDATE_NAVIGATION (state, payload) {
    state.navigation = payload
  },
  UPDATE_FLATNAV (state, payload) {
    state.flatnav = payload
  },
  SET_POLLING (state, status) {
    state.polling = status
  },
  SET_FEATURES_PROVIDER (state, features) {
    state.features.provider = features
  },
  SET_FEATURES_COMPANY (state, features) {
    state.features.company = features
  },
  SET_FEATURES_PROJECT (state, features) {
    state.features.project = features
  },
  SET_FEATURES_COMBINED (state, features) {
    state.combinedFeatures = features
  },
  SET_PLUGIN_INCIDENTS (state, incidents) {
    state.pluginIncidents = incidents
  },
  SET_PROVIDER (state, provider) {
    state.provider = provider
  }
}

export const actions = {
  setProvider ({ commit }, provider) {
    commit('SET_PROVIDER', provider)
  },
  updateNavigation ({ commit, $sentry, state, rootState }, payload = {}) {
    // commit('SET_POLLING', true)
    // const { id: userId } = rootState.access.user.details
    // try {
    //   const marketPlaceRepo = await this.$apiClient.getRepository('marketplace')
    //   if (userId) {
    //     const { incidents: pluginIncidentsRaw } = await marketPlaceRepo.getUserPluginStatus(userId)
    //
    //     const pluginIncidents = Object.entries(pluginIncidentsRaw).map(([key, val]) => {
    //       const sid = Object.keys(val)[0]
    //       if (!Array.isArray(val[sid])) {
    //         return Object.entries(val[sid]).map(([pluginName, data]) => {
    //           return {
    //             pid: key,
    //             sid,
    //             plugin: pluginName,
    //             ...data
    //           }
    //         })
    //       } else {
    //         return {}
    //       }
    //     }).filter(val => Object.keys(val).length !== 0).flat()
    //     commit('SET_PLUGIN_INCIDENTS', pluginIncidents)
    //   }
    //
    //   if (state.provider) {
    //     const { features } = await marketPlaceRepo.getActiveProviderFeatures(state.provider)
    //     commit('SET_FEATURES_PROVIDER', features)
    //   }
    //   if (payload.pid) {
    //     const { features } = await marketPlaceRepo.getActiveProjectFeatures(payload.pid)
    //     commit('SET_FEATURES_PROJECT', features)
    //   }
    // } catch (error) {
    //   $sentry.captureException(error)
    // } finally {
    //   const { provider, company, project } = state.features
    //   commit('SET_FEATURES_COMBINED', [...new Set([...provider, ...company, ...project])])
    //
    //   const newNav = generateNavigation(navigation, state.combinedFeatures)
    //   commit('UPDATE_NAVIGATION', newNav)
    //   commit('UPDATE_FLATNAV', flatNavigation(navigation, state.combinedFeatures))
    //   commit('SET_POLLING', false)
    // }

    const newNav = generateNavigation(navigation, state.combinedFeatures)
    commit('UPDATE_NAVIGATION', newNav)
    commit('UPDATE_FLATNAV', flatNavigation(navigation, state.combinedFeatures))
    commit('SET_POLLING', false)
  },
  overwriteNavMain ({ commit }, payload) {
    commit('OVERWRITE_MAIN_NAV', payload)
  },
  setNavPath ({ commit }, payload) {
    commit('SET_NAV_PATH', payload)
  },
  clearNavPath ({ commit }) {
    commit('CLEAR_NAV_PATH')
  }
}

export const getters = {
  getNavPath: state => state.navPath,
  getNavMain: state => state.navPath.main,
  getNavGroup: state => state.navPath.group,
  getFlatnav: state => state.flatnav,
  getNavigation: state => state.navigation,
  getNavAliases: state => state.navAliases,
  getPolling: state => state.polling,
  getCombinedFeatures: state => state.combinedFeatures,
  getPluginIncidents: state => state.pluginIncidents,
  getNavHeader (state) {
    const group = state.navigation.find(val => val.id === state.navPath.main)
    return {
      label: group?.label,
      description: group?.description
    }
  }

}
