/**
 * Modular Store for uisettings
 */

export const state = () => ({
  cards: {},
  activeCrawlerResultFilter: null
})

export const mutations = {
  SET_CARD (state, payload) {
    state.cards[payload.id] = payload.status
  },
  UPDATE_ACTIVECRAWLER_RESULT_FILTER (state, filters) {
    state.activeCrawlerResultFilter = filters
  }
}

export const actions = {
  setCard ({ commit }, payload) {
    commit('SET_CARD', payload)
  },
  updateActiveCrawlerResultFilter ({ commit }, filters) {
    commit('UPDATE_ACTIVECRAWLER_RESULT_FILTER', filters)
  }
}

export const getters = {
  getCard: state => id => state.cards[id],
  getCards: state => state.cards,
  getActiveCrawlerResultFilter: state => state.activeCrawlerResultFilter
}
