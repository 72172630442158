import { debugEcho } from '~/utils/debugTools'

const PATH_STORE_KEY = '360LoginPath'

export const getStoredInitialPath = () => { return window.sessionStorage.getItem(PATH_STORE_KEY) }

export const storeInitialPath = (route) => {
  debugEcho('PathStore:', { message: 'store path', route })
  const storedPath = getStoredInitialPath()
  debugEcho('PathStore:', { message: 'stored path', storedPath })
  const { fullPath } = route
  if ((!storedPath?.length || storedPath === '/') &&
    fullPath.length > 0 &&
    typeof fullPath === 'string' &&
    !fullPath.includes('[object%20Object]') &&
    !fullPath.includes('helper')) {
    debugEcho('PathStore:', `Storing path: ${fullPath}`)
    window.sessionStorage.setItem(PATH_STORE_KEY, fullPath)
  }
}

export const deleteStoredInitialPath = () => {
  window.sessionStorage.setItem(PATH_STORE_KEY, '')
}

export const cleanUpPath = (routerPath: string) => {
  const initialPath = getStoredInitialPath()
  if (initialPath?.length && initialPath === routerPath) {
    setTimeout(() => {
      deleteStoredInitialPath()
    }, 2000)
  }
}

// export class InitialPath {
//   static readonly PATH_STORE_KEY = '360LoginPath'
//
//   public read () {
//     return window.sessionStorage.getItem(PATH_STORE_KEY)
//   }
//
//   public create (route) {
//     debugEcho('PathStore:', { message: 'store path', route })
//     const storedPath = this.read()
//     debugEcho('PathStore:', { message: 'stored path', storedPath })
//     const { fullPath } = route
//     if (!storedPath?.length && fullPath.length > 0 &&
//       typeof fullPath === 'string' &&
//       !fullPath.includes('[object%20Object]') &&
//       !fullPath.includes('helper')) {
//       window.sessionStorage.setItem(PATH_STORE_KEY, fullPath)
//     }
//   }
//
//   public delete () {
//     window.sessionStorage.setItem(PATH_STORE_KEY, '')
//   }
// }
