/**
 * Modular Store for socket
 */
import io from 'socket.io-client'

let socket
const joinedRooms = []

export const state = () => ({
  socketData: null,
  queue: {},
  socketIsEnabled: true
})

export const mutations = {
  SET_SOCKET_DATA (state, payload) {
    state.socketData = payload
  },
  ADD_TO_QUEUE (state, payload) {
    state.queue = { ...state.queue, ...payload }
  },
  CLEAR_SOCKET (state) {
    state.socketData = null
    state.queue = {}
  },
  DELETE_QUEUE_ENTRY (state, id) {
    delete state.queue[id]
  },
  SET_IS_SOCKET_ENABLED (state, payload) {
    state.socketIsEnabled = payload
  }
}

export const actions = {
  async getSocketData ({ commit, dispatch, $sentry }) {
    if (!this.$apiClient.isConnected()) return
    try {
      const socketRepo = await this.$apiClient.getRepository('websocket')
      const socketData = await socketRepo.getRooms()
      commit('SET_IS_SOCKET_ENABLED', true)
      commit('SET_SOCKET_DATA', socketData)
      dispatch('initSocket')
    } catch (error) {
      $sentry?.captureException(error)
      commit('SET_IS_SOCKET_ENABLED', false)
      console.error('Error getting socket data', error)
    }
  },

  initSocket ({ state, commit }) {
    if (!state.socketIsEnabled) return
    if (!socket) {
      socket = io(state.socketData.server, {
        transports: ['websocket'],
        upgrade: false,
        query: { token: state.socketData.access_token }
      })
    }

    state.socketData.rooms.forEach((room) => {
      if (!joinedRooms.includes(room.room)) {
        socket.emit('join', { room: room.room })
        joinedRooms.push(room.room)
      }
    })

    socket.on('update', (data) => {
      const room = state.socketData.rooms.find(val => val.room === data.room)
      for (const key in room) {
        let roomkey
        switch (key) {
          case 'project_id' :
            roomkey = room[key]
            break
          case 'company_id' :
            roomkey = 'company'
            break
          case 'user_id' :
            roomkey = `u${room[key]}`
            break
        }

        if (roomkey) {
          commit('ADD_TO_QUEUE', { [roomkey]: { message: data.message, action: data.action, attributes: data.attributes } })
        }
      }
      // const id = room?.project_id || room?.company_id ? `c${room.company_id}` : room?.user_id
      // commit('ADD_TO_QUEUE', { [id]: { message: data.message, action: data.action, attributes: data.attributes } })
    })
  },

  clearSocket ({ commit }) {
    commit('CLEAR_SOCKET')
  },

  deleteQueueEntry ({ commit }, id) {
    commit('DELETE_QUEUE_ENTRY', id)
  }
}

export const getters = {
  getSocketData (state) {
    return state.socketData
  },
  getQueue (state) {
    return state.queue
  }
}
