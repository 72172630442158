/**
 * Modular Store for secondScreen
 */

export const state = () => ({
  messages: []
})

export const mutations = {
  ADD_MESSAGE (state, payload) {
    state.messages = [...state.messages, payload]
  }
}

export const actions = {
  addMessage ({ commit, state }, payload) {
    commit('ADD_MESSAGE', payload)
  }
}

export const getters = {
  getMessage: state => (url) => {
    return state.messages.find(val => val.url === url)
  }
}
