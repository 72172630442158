/**
 * Modular Store for whitelabel
 */
import whiteLabelConfig, { localEnvironments } from '~/domain/whitelabelConfig'
import { debugEcho } from '~/utils/debugTools'
export const state = () => ({
  config: {},
  error: '',
  isReady: false
})

export const mutations = {
  SET_CONFIG (state, config) {
    state.config = config
  },
  CLEAR_CONFIG (state) {
    state.config = {}
  },
  SET_ISREADY (state, value) {
    state.isReady = value
  }
}

export const actions = {
  async setupConfig ({ commit, state, $sentry }) {
    const getDomainHostname = window.location.hostname
    const hostname = getDomainHostname === 'localhost'
      ? localEnvironments || 'localhost'
      : getDomainHostname

    if (hostname === 'localhost') {
      commit('SET_CONFIG', whiteLabelConfig.localhost)
      commit('SET_ISREADY', true)
      return
    }

    try {
      const repo = await this.$apiClient.getRepositoryCollection().then(res => res.getApplicationRepository())
      const config = await repo.getConfigByCName(hostname)
      commit('SET_CONFIG', config)
      debugEcho('SET_CONFIG VALUE', config)
      commit('SET_ISREADY', true)
      debugEcho('isReady', state.isReady)
    } catch (error) {
      $sentry?.captureException(error)
      console.error('No config was found for the domain, so the fallback config is used.')
      commit('SET_CONFIG', whiteLabelConfig['localConfig'])

      commit('SET_ISREADY', true)
      debugEcho('isReady', state.isReady)
    }
  },
  clearConfig ({ commit }) {
    commit('CLEAR_CONFIG')
  },
  setReady ({ commit }, { isReady }) {
    commit('SET_ISREADY', isReady)
  }
}

export const getters = {
  getConfig: state => state.config,
  getIsReady: state => state.isReady,
  getIsPartner: state => state.config?.isPartner || false
}
